export const testimonial_data = [
	{
		title: 'General Partner at Entrepreneur',
		desc: "Xhibiter is one of the most exciting, if you're interested in shaping a new business model for creators, this is the team to join.",
		id: '0General Partner at Entrepreneur',
		name: 'Katie Smith',
		img: '/images/testimonials/testimonial_1.jpg',
	},
	{
		title: 'General Partner at Entrepreneur',
		desc: "Xhibiter is one of the most exciting, if you're interested in shaping a new business model for creators, this is the team to join.",
		id: '1General Partner at Entrepreneur',
		name: 'Katie Smith',
		img: '/images/testimonials/testimonial_2.jpg',
	},
	{
		title: 'General Partner at Entrepreneur',
		desc: "Xhibiter is one of the most exciting, if you're interested in shaping a new business model for creators, this is the team to join.",
		id: '2General Partner at Entrepreneur',
		name: 'Katie Smith',
		img: '/images/testimonials/testimonial_3.jpg',
	},
];
