import React, { useEffect, useState } from "react";
import Link from "next/link";
import { services_data } from '../../data/services_data';

const Services = ({categories}) => {


	return (
		<div>
			{/* <!-- Services --> */}
			<section className="py-12 dark:bg-jacarta-900">
				<div className="container max-w-[91rem] px-4">
					<div className="mx-auto mb-12  text-center">
						<h2 className="mb-2 text-center font-display text-3xl font-medium text-jacarta-700 dark:text-white">
							Browse Talent by Category
						</h2>
						<p className="text-lg dark:text-jacarta-300">
							Choose from our ever growing talent pool to find the best match for you
							<br/>or your organization's next task, project or milestone.
						</p>
					</div>
					<div className="grid grid-cols-1 gap-12 md:grid-cols-2 lg:grid-cols-4">
						{categories?.data.map((item) => {
							const { id, icon, name, parent, desc, sub } = item;
							return (
								<>
								{!parent &&
								<>
								<div
									key={id}
									className="service-item relative rounded-2.5xl  bg-white p-10 pt-12 transition-shadow hover:shadow-xl  dark:bg-jacarta-700"
								>
									<img className="icon relative -ml-2 mb-6 h-14 w-14 fill-accent-dark dark:fill-white" src={icon}/>
									<h3 className="mb-4 font-display text-lg text-jacarta-700 dark:text-white">
										{name}
									</h3>

									<ul>
										{categories?.data.map((sub) => {
											const { _id, id, icon, name, parent } = sub;
											return (
												<>
												{parent?._id==item._id &&
												<li className="mt-2">
													<a href={`/category/${_id}`} className="hover:text-accent focus:text-accent">
														{name}
													</a>
												</li> 
												}
												</>
											);
										})}
									</ul>
								
								</div>
								</>
								}    
								</>
							);
						})}
					</div>
				</div>
			</section>
			{/* <!-- end services --> */}
		</div>
	);
};

export default Services;
