import React, { useEffect, useState } from "react";
import { Hero } from "../../components/component";
import Services from "../../components/services/services";
import Testimonial from "../../components/testimonial/Testimonial";
import {
  Partners,
  Browse_category
} from "../../components/component";
import CryptoCounter from "../../components/cryto-trading/CryptoCounter";
import CryptoPrice from "../../components/cryto-trading/price";
import Features from "../../components/cryto-trading/Features";
import ProcessCta from "../../components/cryto-trading/ProcessCta";

import axios from "axios";
import config from "../../utils/config.js";

const Home_1 = () => {

  //  AXIOS REQUEST                            
  const client = axios.create({ baseURL: `${config.API_URL}`});
  const auth = { headers: { Authorization: `Bearer ${config.API_KEY}`}};

  const [categories, setCategories] = React.useState(null);
  const [error, setError] = React.useState(null);

  // GET category
  React.useEffect(() => {
    client.get(`/category`, auth)
    .then((response) => { setCategories(response.data); })
    .catch(error => { console.error(error) });
  }, []);


  return (
    <main>
      <Hero/>
      <Partners />
      <Browse_category categories={categories} />
      <Services  categories={categories} />
      <ProcessCta /> 
      <Features />
      <CryptoCounter />
      <Testimonial />
      <CryptoPrice />
    </main>
  );
};

export default Home_1; 

/*
    Other Optional Components to be added later
    ==============================================



    
*/